<template>
	<div class="content">
		<!-- <u-toast ref="uToast"></u-toast> -->
		<img class="bg_img" src="../../assets/image/bgsf.png"/>
		<div class="box">
			<div class="btns" @click="selectDentity(2)">
				<img v-if="identity != 2" src="../../assets/image/ls0.png" mode="aspectFill"></img>
				<img v-if="identity == 2" src="../../assets/image/ls.png" mode="aspectFill"></img>
			</div>
			<div class="btns" @click="selectDentity(1)">
				<img v-if="identity != 1" src="../../assets/image/xs0.png" mode="aspectFill"></img>
				<img v-if="identity == 1" src="../../assets/image/xs.png" mode="aspectFill"></img>
			</div>
		</div>
		<div class="btn" @click="handleClick(identity)">
			<!-- 确认身份 -->
		</div>
	</div>
</template>

<script>
	export default {
		name: "chooseIdentity",
		data() {
			return {
				tab_index: 0,
				identity: 0
			}
		},
		methods: {
			selectDentity(type) {
				if (type === this.identity) {
					this.identity = 0;
					return
				}
				// console.log(type == 2 ? '老师' : '学生')
				this.identity = type
			},
			handleClick(type) {
				if(type==0){
					this.$toast.fail('请选择你的身份')
					return false
				}
				this.$router.replace({
					name: 'info',
					query: {
						type
					}
				})
			},
		}
	}
</script>

<style scoped lang="scss">
	.bg_img{
		width: 100%;
		height: 100%;
		position: absolute;
	}
	.box {
		width: fit-content;
		height: fit-content;
		position: absolute;
		left: 50%;
		transform: translate(-50%, -50%);
		top: 50%;

		.btns {
			width: 600px;
			height: 200px;
			margin-top: 25px;

			img {
				width: 100%;
				height: 100% !important;
				object-fit: contain;
			}
		}
	}

	.btn {
		width: 560px;
		height: 120px;
		// background: #eee;
		position: absolute;
		z-index: 99;
		left: 50%;
		transform: translateX(-50%);
		top: 82%;
	}
</style>